import React, { useContext, useState } from "react";
import { cn, H3, Icon, IconChevron, TextContainer, useUniqueId } from "../../lib/src";
import * as scss from "./docs-accordion.scss";

export type AccordionSectionProps = {
	/** Header title for an accordion item */
	title: string;
	/** Content within an accordion panel */
	children: React.ReactNode;
	/** Removes max-width for when content is not just text */
	fullWidth?: boolean;
};

const accordionCtx = React.createContext<typeof H3>(H3);

type AccordionProps = {
	children: React.ReactNode;
	headingLevel: typeof H3;
};

export function Accordion(props: AccordionProps): JSX.Element {
	return <accordionCtx.Provider value={props.headingLevel}>{props.children}</accordionCtx.Provider>;
}

function Section(props: AccordionSectionProps) {
	const [open, setOpen] = useState(false);

	const idPrefix = useUniqueId("section");

	const toggle = () => {
		setOpen((s) => !s);
	};

	const Heading = useContext(accordionCtx)!;

	return (
		<div className={scss.section}>
			<div className={scss.header}>
				<TextContainer article>
					<Heading>
						<button
							className={scss.btn}
							aria-expanded={open}
							aria-controls={idPrefix + "-panel"}
							id={idPrefix + "-header"}
							onClick={toggle}
						>
							<Icon rotation={open ? "180" : "0"}>
								<IconChevron />
							</Icon>
							{props.title}
						</button>
					</Heading>
				</TextContainer>
			</div>
			<div
				className={cn(scss.panel, open && scss.shown)}
				id={idPrefix + "-panel"}
				aria-labelledby={idPrefix + "-header"}
				role="region"
			>
				<div className={cn(scss.content, props.fullWidth && scss.fullWidth)}>
					<TextContainer article>{props.children}</TextContainer>
				</div>
			</div>
		</div>
	);
}

Accordion.Section = Section;
