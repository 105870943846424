import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { H2, Paragraph, TextContainer, Ul, Ol, H3 } from "../../lib/src";
import { DocPageMeta, HeaderSection, ContentSection, DocsTable, Code } from "../docs";
import { Accordion } from "../docs/docs-accordion";

export const Meta: DocPageMeta = {
	category: "Designing",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Figma FAQ for designers"
			subTitle="Answers to questions about the Fancy UI kit for Figma."
		/>
		<ContentSection>
			<TextContainer>
				<H2>General</H2>
				<Accordion headingLevel={H3}>
					<Accordion.Section title="How do I get access to the UI kit?">
						<Paragraph>Follow these steps in Figma:</Paragraph>
						<Ol
							items={[
								<>Go to the "Assets" panel in the left sidebar.</>,
								<>Click on the "Team library" icon that looks like an open book.</>,
								<>Click the toggle next to "fancy ui kit" in the modal.</>,
							]}
						></Ol>
					</Accordion.Section>
					<Accordion.Section title="What does the UI kit contain?">
						<Ul
							items={[
								<>
									<a href="https://help.figma.com/hc/en-us/articles/360038746534-Create-color-text-effect-and-layout-grid-styles#Colors_paints">
										Color styles
									</a>{" "}
									that match all of our{" "}
									<GatsbyLink to="https://fancy.siteimprove.com/design-tokens/#Colors">
										design token colors
									</GatsbyLink>
								</>,
								<>
									<a href="https://help.figma.com/hc/en-us/articles/360038746534-Create-color-text-effect-and-layout-grid-styles#Effects">
										Effect styles
									</a>{" "}
									that match most of our{" "}
									<GatsbyLink to="https://fancy.siteimprove.com/design-tokens/#Box%20shadow">
										design token shadows.
									</GatsbyLink>
								</>,
								<>
									<a href="https://help.figma.com/hc/en-us/articles/360038746534-Create-color-text-effect-and-layout-grid-styles#Text">
										Text styles
									</a>{" "}
									that match our <GatsbyLink to="/lib/components/Text/Heading">Heading</GatsbyLink>,{" "}
									<GatsbyLink to="/lib/components/Text/Paragraph">Paragraph</GatsbyLink>, and{" "}
									<GatsbyLink to="/lib/components/Text/Inline text">Inline Text</GatsbyLink>{" "}
									components.
								</>,
								<>
									<a href="https://help.figma.com/hc/en-us/articles/360038662654-Guide-to-Components-in-Figma">
										Components
									</a>{" "}
									that match most of our Lib and lab components.
								</>,
							]}
						/>
					</Accordion.Section>
					<Accordion.Section title="How do I get updates to the UI kit?">
						<Paragraph>
							Figma will notify you and you'll be able to{" "}
							<a href="https://help.figma.com/hc/en-us/articles/360039234193-Review-and-accept-library-updates">
								review and accept the changes
							</a>
							. If your file doesn’t contain something from the UI kit that’s been updated, the
							update will happen silently and automatically.
						</Paragraph>
					</Accordion.Section>
				</Accordion>
				<H2>Colors, shadows, and text</H2>
				<Accordion headingLevel={H3}>
					<Accordion.Section title="How do I use the colors from our design tokens?">
						<Paragraph>
							They are available as{" "}
							<a href="https://help.figma.com/hc/en-us/articles/360038746534-Create-color-text-effect-and-layout-grid-styles#Colors_paints">
								color styles
							</a>
							. This is how to use them:
						</Paragraph>
						<Ol
							items={[
								"Select the object(s) you want to change the color of.",
								<>
									In the "Properties" panel in the right sidebar, under "Fill" or "Stroke", click
									the icon with the four dots.
								</>,
								<>Search and select the color you want to be applied to the selected object(s).</>,
							]}
						/>
					</Accordion.Section>
					<Accordion.Section title="How do I use the shadows from our design tokens?">
						<Paragraph>
							They are available as{" "}
							<a href="https://help.figma.com/hc/en-us/articles/360038746534-Create-color-text-effect-and-layout-grid-styles#Effects">
								effect styles
							</a>
							. This is how to use them:
						</Paragraph>
						<Ol
							items={[
								"Select the object(s) you want to add a shadow to.",
								<>
									In the "Properties" panel in the right sidebar, under "Effects", click the icon
									with the four dots.
								</>,
								<>Search and select the shadow you want to be applied to the selected object(s).</>,
							]}
						/>
					</Accordion.Section>
					<Accordion.Section title="How do I style text so it matches the styling of our text components?">
						<Paragraph>
							When it comes to <GatsbyLink to="/lib/components/Text/Heading">Headings</GatsbyLink>,{" "}
							<GatsbyLink to="/lib/components/Text/Paragraph">Paragraphs</GatsbyLink>, or{" "}
							<GatsbyLink to="/lib/components/Text/Inline text">Inline Text</GatsbyLink>, you can
							either use text styles or text components. The table below shows how the two options
							differ in use.
						</Paragraph>
						<DocsTable>
							<thead>
								<tr>
									<th>Action</th>
									<th>Text style</th>
									<th>Text component</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Insert text</td>
									<td>
										Use{" "}
										<a href="https://help.figma.com/hc/en-us/articles/360039956474#h_36a1ac0a-9576-47d1-8503-3b758ab52ec6">
											text tool
										</a>{" "}
									</td>
									<td>
										Use{" "}
										<a href="https://help.figma.com/hc/en-us/articles/360039150173#assets-panel">
											assets panel
										</a>{" "}
										or{" "}
										<a href="https://help.figma.com/hc/en-us/articles/360039150173#quick-insert">
											quick insert
										</a>
									</td>
								</tr>
								<tr>
									<td>Add styling to text</td>
									<td>
										Use{" "}
										<a href="https://help.figma.com/hc/en-us/articles/360038746534-Create-color-text-effect-and-layout-grid-styles#Text">
											text styles
										</a>
									</td>
									<td>Not necessary (built into component)</td>
								</tr>
								<tr>
									<td>Add color to text</td>
									<td>
										Use{" "}
										<a href="https://help.figma.com/hc/en-us/articles/360038746534-Create-color-text-effect-and-layout-grid-styles#Colors_paints">
											color styles
										</a>
									</td>
									<td>Not necessary (built into component)</td>
								</tr>
								<tr>
									<td>Change styling of text</td>
									<td>
										Use{" "}
										<a href="https://help.figma.com/hc/en-us/articles/360038746534-Create-color-text-effect-and-layout-grid-styles#Text">
											text styles
										</a>
									</td>
									<td>
										Use{" "}
										<a href="https://help.figma.com/hc/en-us/articles/360056440594-Create-and-use-variants#Configure_variants">
											component properties
										</a>
									</td>
								</tr>
								<tr>
									<td>Resize text box</td>
									<td>
										Use{" "}
										<a href="https://help.figma.com/hc/en-us/articles/360039956634-Explore-Text-Properties#h_b332611f-eec6-4fee-a428-e17d2a3554dc">
											properties panel
										</a>
									</td>
									<td>
										Use{" "}
										<a href="https://help.figma.com/hc/en-us/articles/360040451373-Create-dynamic-designs-with-auto-layout#Resizing">
											auto layout resizing properties
										</a>
									</td>
								</tr>
							</tbody>
						</DocsTable>
					</Accordion.Section>
					<Accordion.Section title="How do I create a list that matches our List component?">
						<Paragraph>
							Add the "List" component from the UI kit. It features instructions for how to recreate
							the <GatsbyLink to="/lib/components/Text/List">List</GatsbyLink> component in Figma.
							It was unfortunately not possible to recreate the{" "}
							<GatsbyLink to="/lib/components/Text/List">List</GatsbyLink> component as a dedicated
							text style or component in Figma.
						</Paragraph>
					</Accordion.Section>
					<Accordion.Section title="How do I find out how much spacing to add between text components?">
						<Paragraph>
							Add the "Text Container" component from the UI kit to your file. It’s unfortunately
							not a component that automatically adds appropriate vertical spacing. Instead, it’s an
							overview of appropriate spacing values between text component pairs.
						</Paragraph>
					</Accordion.Section>
				</Accordion>
				<H2>Components</H2>
				<Accordion headingLevel={H3}>
					<Accordion.Section title="How do I add a component from the UI kit?">
						<Paragraph>
							You can either use the{" "}
							<a href="https://help.figma.com/hc/en-us/articles/360039150173-Create-and-insert-component-instances#assets-panel">
								Assets panel
							</a>{" "}
							in the left sidebar or the{" "}
							<a href="https://help.figma.com/hc/en-us/articles/360039150173-Create-and-insert-component-instances#quick-insert">
								quick insert
							</a>{" "}
							shortcut <Code>Shift + I</Code>. Both options enable you to search or browse for a
							component.
						</Paragraph>
					</Accordion.Section>
					<Accordion.Section title="Are all Fancy components available in the UI kit?">
						<Paragraph>
							No, but most are. There are various reasons for why some components are missing:
						</Paragraph>
						<Ol
							items={[
								<>
									We haven't added them yet. These components are marked in the UI kit with the
									label "(missing)".
								</>,
								<>
									They can't be recreated in Figma. An example is the{" "}
									<GatsbyLink to="/lib/components/Text/Text container">Text Container</GatsbyLink>{" "}
									component, which automatically adds correct vertical spacing between headings,
									paragraphs, and lists. Instead, these components are available in the UI kit as
									instructions for how to create them manually in Figma. They are marked with the
									label "(instructions)".
								</>,
								<>
									They are non-visual technical components that don’t make sense within the context
									of a UI design tool. The{" "}
									<GatsbyLink to="/lib/components/Text/Screen reader only (SrOnly)">
										Screen Reader Only (SrOnly)
									</GatsbyLink>{" "}
									component is an example of such a component. These are entirely absent from the UI
									kit.
								</>,
							]}
						/>
					</Accordion.Section>
					<Accordion.Section title="Are all components in the UI kit Fancy components?">
						<Paragraph>No, but most are. There are two exceptions:</Paragraph>
						<Ol
							items={[
								<>
									Components grouped under "_platform". These are non-Fancy UI elements from the
									Siteimprove Platform, such as the left hand navigation menu.
								</>,
								<>
									Components grouped under "_templates". These are common combinations of Fancy and
									"_platform" components. They are meant to be used as starting points for mockups,
									so you don't have to assemble and design everything from scratch.
								</>,
							]}
						/>
					</Accordion.Section>
					<Accordion.Section title="How do I find out whether a component is a Lab or Lib component?">
						<Paragraph>
							A popover will tell you when you hover over the component in the "Assets" panel.
							Alternatively, if you've already added the component, select it and go to the
							"Inspect" panel in the right sidebar.
						</Paragraph>
					</Accordion.Section>
					<Accordion.Section title="How do I quickly access a component's page in the styleguide?">
						<Ol
							items={[
								"Select the component.",
								<>Go to the "Inspect" panel in the right sidebar.</>,
								<>Click on the "View documentation" button".</>,
							]}
						/>
					</Accordion.Section>
					<Accordion.Section title="How do I edit a component's label?">
						<Paragraph>
							The quickest way is to activate the text tool (press <Code>T</Code>) and click on the
							label. Another method is to hold down the <Code>Command (⌘)</Code> key and double
							click on the label.
						</Paragraph>
					</Accordion.Section>
					<Accordion.Section title="How do I change a component's property values?">
						<Paragraph>
							Select the component and use the dropdown menus and toggles beneath the component's
							name in the right sidebar.
						</Paragraph>
						<Paragraph>
							Note that some components contain nested components. To adjust a nested component’s
							property values, you first need to select it. You can do this by clicking on its
							layer, marked by a diamond icon. You can also select a nested component by double
							clicking on its parent component (multiple times).
						</Paragraph>
					</Accordion.Section>
					<Accordion.Section title="Do the UI kit components have the same properties as their code counterparts?">
						<Paragraph>
							No, the Figma components often feature a subset of their code counterpart's
							properties. Some properties simply don't make sense within the context of a UI design
							tool or couldn't be recreated. Examples are the <Code>data-obsere-key</Code>,{" "}
							<Code>children</Code>, and <Code>className</Code> properties. Shared properties have
							been given the same names, though. This should facilicate communication with
							developers.
						</Paragraph>
					</Accordion.Section>
					<Accordion.Section title="How do I nest layers inside of component instances?">
						<Paragraph>
							You can't, unfortunately. This is particularly frustrating when it comes to components
							that are meant to function as containers, such as the{" "}
							<GatsbyLink to="/lib/components/Structure/Card">Card</GatsbyLink> component. As a
							result, when you've perfectly aligned some text within a Card and then move that Card,
							the text will stay put.
						</Paragraph>
						<Paragraph>Fortunately, there's a workaround:</Paragraph>
						<Ol
							items={[
								<>
									Select the container component and press <Code>Option (⌥) + Command (⌘) + G</Code>
									. This wraps the component in a{" "}
									<a href="https://help.figma.com/hc/en-us/articles/360041539473-Frames-in-Figma">
										frame
									</a>
									.
								</>,
								"Place whatever layers you want inside the frame alongside the container component.",
								"Now, when you move the frame, both the component and the layers move together.",
							]}
						/>
						<Paragraph>
							A downside to this workaround is that the container component and frame can't be
							resized simultaneously. The workaround for this issue is to resize the component
							first, then select the frame and press the{" "}
							<a href="https://www.figma.com/best-practices/groups-versus-frames/frames/#frame-sizes">
								“Resize to fit”
							</a>{" "}
							button (or use the <Code>Option (⌥) + Shift + Command (⌘) + R</Code> shortcut).
						</Paragraph>
					</Accordion.Section>
				</Accordion>
				<H2>Forms</H2>
				<Accordion headingLevel={H3}>
					<Accordion.Section
						title={`Why is there two versions of every "Forms and Input" component?`}
					>
						<Paragraph>
							The “Standalone” version is just the form component. The “Form element wrapper”
							version adds many of the options of the{" "}
							<GatsbyLink to="/lib/components/Forms and input/Form element wrapper">
								component with the same name
							</GatsbyLink>
							. That means it provides the form component with a label and optional tooltip,
							helptext, and error message.
						</Paragraph>
					</Accordion.Section>
					<Accordion.Section
						title={`How do I create prototypes with the "Checkbox" and "Radios" components?`}
					>
						<Paragraph>
							The interactive behavior of checkboxes and radio buttons is built into these
							components. That means you don’t need to create and link frames for every possible
							selection state. You only need to specify the number of radio buttons or checkboxes
							you want and change the labels of the options.
						</Paragraph>
					</Accordion.Section>
					<Accordion.Section
						title={`How do I create prototypes with the "Input Field" and "Text Area" components?`}
					>
						<Paragraph>Both components contain three text layers:</Paragraph>
						<Ul items={["placeholder", "value 1", "value 2"]} />
						<Paragraph>
							Only one textlayer is visible at a time. Which one that is, is controlled through the
							“value” property. When you click on one of the components in a prototype, the “value”
							property changes like this:
						</Paragraph>
						<Ul
							items={[
								"placeholder becomes value 1",
								"value 1 becomes value 2",
								"value 2 stays value 2",
							]}
						/>
						<Paragraph>So, when you set up your prototype, perform the following steps:</Paragraph>
						<Ol
							items={[
								"Toggle the visibility of the text layers one by one and edit the labels to what you want them to be.",
								"Change the visibility of the text layers back to how they were before you started editing them.",
								"Set the “value” property to the text layer you want to be visible from the start of the prototype.",
							]}
						/>
					</Accordion.Section>
					<Accordion.Section title={`How do I create prototypes with the "Dropdown" components?`}>
						<Paragraph>
							It's possible to create a prototype with a dropdown button that opens a popover, which
							closes when either the button or something in the popover is clicked. What's not
							possible, is to make the button label change to whatever item has been clicked in the
							popover. That is, not unless you're willing to create a large number of frames and
							prototype connections.
						</Paragraph>
						<Paragraph>
							The workflow is similar for the different dropdown components (
							<GatsbyLink to="/lab/components/Actions and controls/Action menu">Action</GatsbyLink>,{" "}
							<GatsbyLink to="/lab/components/Overlay/Popover">Base</GatsbyLink>,{" "}
							<GatsbyLink to="/lab/components/Forms and input/Dropdown">Regular</GatsbyLink>,{" "}
							<GatsbyLink to="/lab/components/Forms and input/Searchable dropdown">
								Searchable
							</GatsbyLink>
							, and{" "}
							<GatsbyLink to="/lab/components/Forms and input/Multiselect dropdown">
								Multiselect
							</GatsbyLink>
							):
						</Paragraph>
						<Ol
							items={[
								"Add two instances of the dropdown's button and a single popover.",
								"Place the first button in a frame and customize its label. Place the popover and the second button outside the frame.",
								<>
									Edit the second button's label so it matches the first button's label. Also,
									toggle on the second button's "open" property.
								</>,

								"Customize the items in the popover.",
								"Place the popover 4 pixels away from the second button.",
								<>
									Select the popover and second button and place them in a frame together. You can
									use the <Code>Option (⌥) + Command (⌘) + G</Code> shortcut for this.
								</>,
								"Create a prototype connection between the first button and the frame containing the second button and the popover.",
								<>
									Use an “On click” trigger to open the frame as an overlay, set the overlay’s
									position to “Manual”, and check the “Close when clicking outside".
								</>,
							]}
						/>
					</Accordion.Section>
					<Accordion.Section title="How do I layout a form?">
						<Paragraph>
							Add the "Form" component from the UI kit to your file. Unfortunately, it’s not a
							component that automatically lays out a form correctly. Instead, it features
							step-by-step instructions for how to assemble, layout, and place a form.
						</Paragraph>
					</Accordion.Section>
				</Accordion>
			</TextContainer>
		</ContentSection>
	</>
);
